.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.MuiInputBase-root {
  height: 2.8pc;
}

.MuiOutlinedInput-notchedOutline{
border-color: rgb(225,225,225) !important;
border-radius: 0 !important;
border-style: ridge;
top: -4.8x !important;
box-shadow: 0px 3px 10px rgb(230,230,230) !important ;
}

.MuiSvgIcon-root {
  color: red;
}

.tab-content {
  max-height: 0;
  -webkit-transition: max-height 0.35s;
  -o-transition: max-height 0.35s;
  transition: max-height 0.35s;
}

.tab input:checked ~ .tab-content {
  max-height: 100vh;
}

.tab input:checked + label {
  border-left-width: 1px; 
  border-color: white;
}

.tab .label .icon {
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  transform-origin: center center;
}

.tab input[type="checkbox"]:checked + .label .icon {
  transform: rotate(135deg);
}

ul a {
  transition: color 0.5s ease;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  font-size: 78% !important;
  color:#000 !important;
  font-weight: 400 !important;
}

.css-i4bv87-MuiSvgIcon-root {
  height: 18px !important;
}

/* Google place autocomplete */
.css-yk16xz-control {
  width: 20rem !important;
  border-radius: 0% !important;
}

.css-2b097c-container {
  width: 20rem !important;
  border: 0ch !important;
}

.css-1hwfws3{
  width: 20rem !important;
  border: 0ch !important;
}

.css-1pahdxg-control:hover {
  box-shadow: 0 0 0 1px #282c34 !important;
  border: 0 !important;
}

.css-1pahdxg-control {
  box-shadow: 0 0 0 1px #282c34 !important;
  border-radius: 0px !important;
  border: 0 !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
