@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Heebo&display=swap');

:root{
   --boxShadow : rgb(0, 0, 0, 0.16);
}

@layer base {
  body{
    font-family : 'Segoe UI' , sans-serif;
    font-size : 14px;
  }
  li {
    @apply pr-2
  }
}